<template>
  <!--  开户申请单 -->
  <div class="account">
    <div class="account-top">
      {{ USER_EDIT ? "编辑开户申请" : "开户申请表单" }}
    </div>
    <van-form @submit="onSubmit">
      <van-field
        v-model="userDetailObject.enterpriseName"
        label="企业名称"
        required
        placeholder="请输入企业名称"
        :rules="[{ required: true }]"
      />
      <van-field
        v-model="userDetailObject.brandName"
        label="品牌名称"
        required
        placeholder="请输入客户品牌名称"
        :rules="[{ required: true }]"
      />
      <van-field
        customerName
        v-model="userDetailObject.customerName"
        label="客户姓名"
        placeholder="请输入客户姓名"
      />
      <van-field
        v-model="userDetailObject.customerMobile"
        label="客户手机号"
        required
        placeholder="请输入客户手机号"
        :rules="[
          { required: true, message: '手机号码不能为空' },
          { pattern: /^1[3-9]\d{9}$/, message: '请输入正确的手机号码' },
        ]"
      />
      <!-- 搜索 -->
      <van-field
        label="代码行业"
        readonly
        clickable
        required
        placeholder="请选择代码行业"
        @click="showClick"
        :value="userDetailObject.industryName"
      ></van-field>
      <van-popup v-model="showPicker" position="bottom">
        <!-- v-model.trim 对关键字进行去空处理 @input 输入框自身属性，对输入的值进行实时检测 -->
        <van-field
          clickable
          v-model.trim="keyvalue"
          left-icon="search"
          placeholder="搜索所在行业"
          @input="search"
        ></van-field>
        <van-picker
          show-toolbar
          text-key="industryName"
          :columns="filterdepartments"
          @confirm="onConfirm"
          @cancel="showPicker = false"
        >
          <template #option="option">
            <div>{{ option.industryName }}</div>
          </template>
        </van-picker>
      </van-popup>
      <!-- 上传合同 -->
      <van-field name="fileContractList" label="合同凭证" required :rules="[{ required: true, message: '请上传合同凭证' }]">
        <template #input>
          <van-uploader
            v-model="fileContractList"
            @delete="clearContractList"
            :max-count="20"
            :after-read="afterContractRead"
          />
        </template>
      </van-field>
      <!-- 上传图片 -->
      <van-field name="uploader" label="支付凭证截图">
        <template #input>
          <van-uploader
            v-model="fileList"
            @delete="clearfileList"
            :max-count="4"
            :after-read="afterRead"
          />
        </template>
      </van-field>
      <div class="paymentVoucher">请上传支付凭证截图</div>
      <div class="paymentVoucher">请确保图片上传成功,否则图片上传失败</div>
      <div></div>
      <div style="margin: 16px">
        <van-button
          size="small"
          block
          type="info"
          native-type="submit"
          :disabled="unloadeFlage"
          >提交</van-button
        >
      </div>
    </van-form>
  </div>
</template>

<script>
// 
import { getUserSalesDetail, setUserDetailInformation } from "@/api/user/index";
import { uploadImg } from "@/api/upload/uploadimage";
import { getCodeList } from "@/api/setUserDetail/setUserDetail";
import {  Notify } from "vant";
export default {
  name: "setUserDetail",
  data() {
    return {
      userDetailObject: {
        brandName: "",
        customerName: "",
        customerMobile: "",
        industryCode: "",
        industryName: "",
        payVoucherPic: "",
        enterpriseName:''
      },
      value: "",
      columns: [],
      showPicker: false,
      unloadeFlage: false, // 上传文件状态
      fileList: [],
      fileContractList:[],
      getfileContractList:[],
      keyvalue: "",
      filterdepartments: [],
    };
  },
  computed: {
    // 判断是编辑还是创建
    USER_EDIT() {
      return !!this.$route.params.userSalesId;
    },
  },
  created() {
    this.$nextTick(() => {
      this.getCodeList();
    });
    // 遗留一个问题图片上传没成功之前传值。会出现空的图片地址
  },
  mounted() {
    //  挂载时，将原数组给到需要过滤的数组
    this.filterdepartments = this.columns;
  },
  methods: {
    // 确认选择框
    showClick() {
      this.showPicker = true;
      this.keyvalue = "";
      this.filterdepartments = this.columns;
    },
    /**
     * 代码描述: 获取用户参数接口
     * 作者:liushiban
     * 创建时间:
     */
    getUserSalesDetail() {
      let { userSalesId } = this.$route.params;
      getUserSalesDetail(userSalesId)
        .then((res) => {
          if (res.status == "200") {
            let { accountOpenApplication } = res.data.data;
            this.userDetailObject = accountOpenApplication;
            // 处理数组默认展示参数
            if (this.columns.length > 0) {
              this.filterdepartments = this.columns.filter((item) => {
                return item.industryCode == accountOpenApplication.industryCode;
              })
            }
            this.fileList = [];
            if (accountOpenApplication.payVoucherPic !== ""){
              this.fileList.push({ url: accountOpenApplication.payVoucherPic });
            }else if(accountOpenApplication.electronicContract &&JSON.parse(accountOpenApplication.electronicContract).length>0){
                  this.getfileContractList = JSON.parse(accountOpenApplication.electronicContract)
                  this.fileContractList = JSON.parse(accountOpenApplication.electronicContract)
              }else{
               console.log('成功')
              }
            }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    /**
     * 代码描述: 提交按钮
     * 作者:liushiban
     * 创建时间:
     */
    onSubmit() {
      this.unloadeFlage = true
      this.userDetailObject.electronicContract = JSON.stringify(this.getfileContractList)
      setUserDetailInformation(this.userDetailObject)
        .then((res) => {
          if (res.status == "200") {
            this.unloadeFlage = false
            this.$router.push({
              name: "index",
            })
            Notify({
              type: "success",
              message: this.USER_EDIT? '编辑成功':'申请开户成功',
            });
          }
          this.$tools.setTimeMiao(()=>{
              this.unloadeFlage = false
              },2000)
        })
        .catch((error) => {
          this.$tools.setTimeMiao(()=>{
          this.unloadeFlage = false
          },2000)
          Notify({
              type: "danger",
              message: this.USER_EDIT? '编辑失败':'申请开户失败',
            });
          return error;
        })
      // 进行下一步跳转页面
    },
    afterRead(file) {

      // 这边处理和后端保持一致，不用组件自带的base64格式。拿原生的对象形式
      let image = file.file;
      // 文件上传需要用new FormData()转换
      let formData = new FormData();
      // 添加文件和其他字段到 FormData 对象中
      formData.append("file", image);
      formData.append("module", 1);
      file.status = "uploading";
      file.message = "上传中...";
      this.unloadeFlage = true;
      uploadImg(formData).then((response) => {
          file.status = "done";
          // let { url } = response.data.data;
          this.userDetailObject.payVoucherPic = response;
          this.unloadeFlage = false;
        })
        .catch((error) => {
          // 请求失败的处理逻辑
          file.status = "failed";
          file.message = "上传失败";
          console.error("请求出错：", error);
          this.unloadeFlage = false;
        });
    },
    // 合同上传
    afterContractRead(file) {
      // 这边处理和后端保持一致，不用组件自带的base64格式。拿原生的对象形式
      let image = file.file;
      // 文件上传需要用new FormData()转换
      let formData = new FormData();
      // 添加文件和其他字段到 FormData 对象中
      formData.append("file", image);
      formData.append("module", 1);
      file.status = "uploading";
      file.message = "上传中...";
      this.unloadeFlage = true;
      uploadImg(formData).then((response) => {
          file.status = "done";
          // let { url } = response.data.data;
    
          this.getfileContractList.push({url:response})
          this.unloadeFlage = false;
        })
        .catch((error) => {
          // 请求失败的处理逻辑
          file.status = "failed";
          file.message = "上传失败";
          console.error("请求出错：", error);
          this.unloadeFlage = false;
        });
    },
    /**
    * 代码描述: 合同删除
    * 作者:liushiban
    * 创建时间:
    */
    clearContractList(m){
      if (this.userDetailObject.electronicContract == "") return;
      this.getfileContractList = this.getfileContractList.filter(item=>{
        return item.url !== m.url
      })
    },
    /**
     * 代码描述:清楚图片操作
     * 作者:liushiban
     * 创建时间:
     */
    // 清楚按钮的时候手动删掉已有的元素
    clearfileList() {
      if (this.userDetailObject.payVoucherPic == "") return;
      this.$set(this.userDetailObject, "payVoucherPic", "");
    },
    // 输入框搜索方法
    search() {
      // 对过滤数组进行操作，返回符合条件的数组
      this.filterdepartments = this.columns.filter((item) => {
        return item.industryName.includes(this.keyvalue);
      });
    },
    // 选择值页面
    onConfirm(value) {
      if(!value) return
      this.value = value;
      let { industryCode, industryName } = value || '';
      this.userDetailObject.industryCode = industryCode || '';
      this.userDetailObject.industryName = industryName || '';
      this.search();
      this.showPicker = false;
    },

    /**
     * 代码描述: 获取代码列表
     * 作者:liushiban
     * 创建时间:
     */
    getCodeList() {
      getCodeList()
        .then((res) => {
          this.columns = res.data;
          if (this.USER_EDIT) {
            this.getUserSalesDetail();
          }
        })
        .catch((error) => {
          this.columns = [];
          return error;
        });
    }
  },
};
</script>

<style scoped lang="scss">
.account {
  padding: 20px;
  .account-top {
    font-size: 16px;
    font-weight: 600;
    text-align: left;
    padding-bottom: 20px;
  }
}
.paymentVoucher {
  color: #c7c7c7;
  font-size: 14px;
}
select {
  border: none;
  outline: none;
  height: 30px;
}
</style>
