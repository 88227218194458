// 引入request
import request from '@/untils/request'

let QnyTokenTime = localStorage.getItem('QnyTokenTime')
// 获取千牛云token
let QnyToken = 	localStorage.getItem('QnyToken')
// 获取失效时间
let Tokentime = localStorage.getItem('Tokentime')

async function getQnyToken() {
  // 返回一个 Promise 对象
  return new Promise((resolve, reject) => {

    // 发送网络请求
    request({
      method: 'POST',
      url: 'https://api.taoxingyan.com/service/callingInterface/sendRequestUrl',
      data:{
        methodUrl: "/brand/admin_get_upload_info",
        requestType: "GET",
        params: `{"param":${Date.now()}}`
      }
    })
      .then(response => {
        console.log('获取token=====', response);
        // 如果请求成功，调用 resolve 将结果返回
        let res = response.data;
        if (res.code == 1) {
          resolve(res.data);
        } else {
          reject(res);
        }
        
      })
      .catch(error => {
        // 如果请求失败，调用 reject 将错误信息返回
        reject(error);
      });
  });
}

export async function uploadImg(formData) {
  
  formData.timestamp = Date.now()
  if(!QnyTokenTime){
    
    // 处理第一调用
    let res = await getQnyToken()
    localStorage.setItem('Tokentime',res.expires)
    localStorage.setItem('QnyToken',res.token)
    localStorage.setItem('QnyTokenTime', Date.now());
    QnyToken = res.token 
    formData.append("token", QnyToken);
    formData.append("key", `logo/${Date.now()}.png`);
      return uploadImgFile(formData)
  }else{
    if(	(formData.timestamp - QnyTokenTime) /1000  >=  Tokentime - 10000){
        let res = await getQnyToken()
        localStorage.setItem('Tokentime',res.expires)
        localStorage.setItem('QnyToken',res.token)
        localStorage.setItem('QnyTokenTime', Date.now());
        QnyToken = res.token 
        formData.append("token", QnyToken);
        formData.append("key", `logo/${Date.now()}.png`);
        return uploadImgFile(formData)
    }else{
      formData.append("token", QnyToken);
      formData.append("key", `logo/${Date.now()}.png`);

      return uploadImgFile(formData)
    }
  }
}

export function uploadImgFile(formData) {

  // 返回一个 Promise 对象
  return new Promise((resolve, reject) => {
    // 发送网络请求
    request({
      method: 'POST',
      url: `https://up.qiniup.com/upload/img`,
      headers: { 'Content-Type': 'multipart/form-data' },
      data: formData
    })
      .then(response => {
        // 如果请求成功，调用 resolve 将结果返回
        let backUrl = 'http://static.taoxingyan.com/' + response.data.key;
        resolve(backUrl);
      })
      .catch(error => {
        // 如果请求失败，调用 reject 将错误信息返回
        reject(error);
      });
  });
}